<template>
  <div class="float-suggestions-search" v-if="show">
    <strong class="text-muted d-block">{{user_data && user_data.main_group.name === 'creator' ? $t('creator.trending_platforms_campaging') : $t('creator.trending_platforms_suggestions')}}</strong>
    <b-button pill
      size="sm"
      v-for="(net, index) in network" 
      :key="index" 
      class="mr-1 mt-1 btn-float-suggestions" 
      variant="btn-float-suggestions"
      @click="clickRedirection('network', net)"
    >
      <div class="d-flex align-items-center">
        <i v-if="net !== 'blog' && net !== 'twitter'" :class="getClassNetworkColor(net)" class="icon-network-work"></i>
        <b-img v-else-if="net === 'blog'" :src="getClassNetworkColor(net)" fluid class="icon-wordpress"/>
        <b-img v-else-if="net === 'twitter'" :src="getClassNetworkColor(net)" rounded="circle" class="icon-content-twitter-sugestion"/>
        <span class="text-network">{{nameNetworks(net)}}</span>
      </div>
    </b-button>
    <div v-if="!user_data || (user_data.main_group.name !== 'creator')">
      <strong class="text-muted d-block my-1">{{$t('creator.work_type_suggestions')}}</strong>
      <b-button
        pill
        :class="classButtonSelected(work_type.value)" 
        variant="btn-float-category" 
        v-for="(work_type) in $t('creator.type_of_work')"
        :key="work_type.value"
        @click="clickRedirection('work_types', work_type.value)"
      >
        <span class="text-category">{{work_type.text}}</span>
      </b-button>
    </div>
    <div v-else-if="user_data && user_data.main_group.name === 'creator'">
      <strong class="text-muted d-block my-1">{{$t('creator.trending_categories')}}</strong>
      <b-button
        pill
        :class="classButtonSelected(content_type.value)" 
        variant="btn-float-category" 
        v-for="(content_type) in $t('creator.type_of_service')"
        :key="content_type.value"
        @click="clickRedirection('content_type', content_type.value)"
      >
        <span class="text-category">{{content_type.text}}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
} from 'bootstrap-vue';
import { getClassNetworkColor } from '@/libs/utils/icons';
import { nameNetworks } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';


export default {
  name: 'suggestionsContainer',
  components: {
    BButton,
    BImg,
  },
  props: {
    show_suggestions: {
      type: Boolean,
      required: true,
      default: false
    },
    list_selected: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      nameNetworks,
      getClassNetworkColor,
      show: this.show_suggestions,
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      user_data: getUserData(),
    }
  },
  methods: {
    clickRedirection(key_name, value) {
      if (Object.keys(this.list_selected).length > 0) {
        if (!this.user_data || (this.user_data.main_group.name !== 'creator')) {
          this.list_selected.list_button.push(value);
          this.list_selected.selected = true;
          this.list_selected.type_button = key_name; 
          this.$router.push({name: 'search_proposal', query: {[key_name]: value}});
          this.$root.$emit('update_proposals', this.list_selected);
        } else {
          this.list_selected.list_button.push(value);
          this.list_selected.selected = true;
          this.list_selected.type_button = key_name; 
          this.$router.push({name: 'casting_calls', query: {[key_name]: value}});
          this.$root.$emit('update_casting_calls', this.list_selected);
        }
      }
    },
    classButtonSelected(type) {
      if (Object.keys(this.list_selected).length > 0) {
        if (this.list_selected.selected) {
          return this.list_selected.list_button.find(value => value === type) ? 'btn-float-category-selected mr-1 mb-1' : 'btn-float-category mr-1 mb-1'
        } else {
          return 'btn-float-category mr-1 mb-1'
        }
      }
    }
  }
}
</script>
<style scoped>
.text-network {
  font-size: x-small;
  margin-left: 3px;
  font-family: 'avenir-medium';
}
.text-category {
  display: flex;
  font-size: x-small;
  font-family: 'avenir-medium';
}
.icon-network-work {
  font-size: 16px;
  padding: 4px !important;
  color: white !important;
}
.icon-wordpress {
  height: 24px;
  width: 24px;
}
.float-suggestions-search {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 4em;
  z-index: 100;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.btn-float-suggestions {
  background-color: transparent !important;
  padding: 6px 12px 6px 2px;
  color: #444;
  margin-right: 6px !important;
  border-color: rgba(222,226,230,.7);
  cursor: pointer;
}
.btn-float-suggestions:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 14px 0px;
}
.btn-float-category-selected {
  background-color: #212529 !important;
  color: white;
  padding: 10px 14px;
}
.btn-float-category {
  background-color: rgba(222,226,230,.7) !important;
  padding: 10px 14px;
  color: #212529;
  cursor: pointer;
}
.btn-float-category:hover {
  background-color: #212529 !important;
  color: white;
  cursor: pointer;
}
.img-network-badge {
  width: 18px;
  height: 18px;
}
.icon-content-twitter-sugestion {
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
}
</style>