<template>
  <div class="navbar-header d-xl-block d-none" style="width: 300px;">
    <ul class="nav navbar-nav">
      <li class="nav-item d-flex">
        <b-link
          class="navbar-brand mr-2"
          :to="checkRedirect()"
        >
          <span class="brand-logo">
            <b-img
              v-if="checkCoca()"
              src="@/assets/images/logo/coca_logo.png"
              alt="Logo"
              style="max-width: 100%"
            />
            <b-img
              v-else-if="checkOutHome()"
              fluid
              src="@/assets/images/logo/ooh.avif"
              alt="Logo"
              style="max-width: 100px"
            />
            <b-img
              v-else
              src="@/assets/images/logo/logo-brandme.png"
              alt="Logo"
              style="max-width: 100px"
            />
          </span>
        </b-link>
        <div v-if="routePath() && !checkOutHome()">
          <div class="position-relative container-search">
            <b-form-input
              class="input-search-proposal"
              :placeholder="user_data && (user_data.main_group.name === 'creator') ? $t('creator.placeholder_suggestions_creator') : $t('creator.placeholder_suggestions_brand')"
              v-model="search"
              @keyup.enter="searchQuery()"
              trim
              @focus="show_suggestions = true; update_suggestions = !update_suggestions"
              @blur="blurActionInput"
              autocomplete="off"
            > 
            </b-form-input>
            <b-icon
              class="icon-search"
              icon="search"
              @click="searchQuery"
            ></b-icon>
            <suggestions-container 
              :show_suggestions="show_suggestions"
              :key="update_suggestions"
              :list_selected="button_Selected"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { 
  BLink,
  BFormInput,
  BImg,
} from 'bootstrap-vue';
import utils from "@/libs/utils";
import suggestionsContainer from '@/views/components/proposals/suggestionsContainer.vue';

export default {
  components: {
    BLink,
    BFormInput,
    BImg,
    suggestionsContainer
  },
  data() {
    return {
      utils,
      search: this.$route.query.query ? this.$route.query.query : '',
      show_suggestions: false,
      update_suggestions: false,
      user_data: utils.getUserData(),
      is_creator: this.user_data && this.user_data.is_superuser ? this.user_data.main_group.name === 'creator' : false,
      button_Selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
    }
  },
  created () {
    this.rutaDominio = window.location.host;
    this.$root.$on('update_color_button_selected', (selected_Button) => {
      this.button_Selected = selected_Button;
    });
    this.$root.$on('update_search_query', (search) => {
      this.search = search;
    });
  },
  methods: {
    routePath() {
      if (!this.$route.fullPath.includes('home-search') && !this.$route.fullPath.includes('email-confirm') &&  !this.$route.fullPath.includes('additional-information') && !this.$route.fullPath.includes('/roll')) {
        return true
      }
    },
    checkCoca() {
      return this.rutaDominio.includes('coca')
    },
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    },
    checkRedirect() {
      if (this.checkOutHome()) return '/campaigns'
      else if (this.is_creator || this.checkCreatorPublicViews()) return '/home-search';
      return '/'
    },
    checkCreatorPublicViews() {
      
      if (!utils.getUserData()) {
        return true
      } else if (utils.getUserData()) {
        return true
      } else {
        return false  
      }
    },
    searchQuery() {
      if (!this.user_data || (this.user_data.main_group.name !== 'creator')) {
        (this.search.length > 0) ? this.$router.push({name: 'search_proposal', query: {query: this.search}}) : this.$router.push({name: 'search_proposal'})
        this.$root.$emit('update_proposals');
      } else if (this.user_data.main_group.name === 'creator') {
        (this.search.length > 0) ?  this.$router.push({name: 'casting_calls', query: {query: this.search}}) : this.$router.push({name: 'casting_calls'})
        this.$root.$emit('update_casting_calls');
      }
    },
    blurActionInput() {
      setTimeout(() => {        
        this.show_suggestions = false;
        this.update_suggestions = !this.update_suggestions;
      }, 200);
    },
    clickOnNetwork(net) {
      this.$router.push({name: 'search_proposal', query: {network: net}}); 
      this.$root.$emit('update_proposals');
    }
  }
}
</script>

<style scoped>
.container-search .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 2.4em;
  width: 400px;
  height: 38px;
}
.icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  top: 0.5em;
  left: 0.5em;
  font-size: 20px;
  cursor: pointer;
}
.float-suggestions-search {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 3.5em;
  z-index: 100;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.badge-float-suggestions {
  background-color: #f1f1f1 !important;
  color: #444;
  cursor: pointer;
}
.badge-float-suggestions:hover {
  background-color: #e2e2e2 !important;
}
.img-network-badge {
  width: 18px;
  height: 18px;
}
</style>
