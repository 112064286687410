<template>
  <div>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    menu-class="size-submenu"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0" style="color: white !important">
          {{userData ? userData.first_name || userData.first_name : ""}}
        </p>
        <span class="user-status text-white" v-if="userData">{{userData.main_group.name == 'espejo' ? utils.capitalize($t('header.typeUser.personalizedService')) : getNameRol()}}</span>
      </div>
      <b-avatar
        v-if="userData"
        size="40"
        :src="userData && userData.profile_image !== null ? getImgProfile(userData.profile_image) : ''"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      <feather-icon v-if="userData && userData.profile_image === null" icon="UserIcon" size="22" class="text-white" />
      </b-avatar>
    </template>

    <b-dropdown-item @click="redirectEmail()">
      <b-row>
        <span class="container-username"> {{userData ? userData.first_name || userData.first_name : ""}} {{userData ? userData.last_name || userData.last_name : "" }}</span>
        <span class="container-email"> {{userData.email}}</span>
      </b-row>
      </b-dropdown-item>
      <b-dropdown-divider class="px-1"/>
      <b-dropdown-item
        :to="{ name: 'brandme_AI', params: { section: 'community' } }"
        link-class="p-0"
      >
        <div @mouseover="hovered = true" @mouseleave="hovered = false" class="d-flex justify-content-between menu-dropdown padding-nav-item">
          <div>
            <span class="">{{$t('creator.creditsIa')}}: </span>
            <small class="text-muted d-flex">{{$t('creator.remainings')}}: {{ credits >= 0 ? separatebycomma(credits) : '0'}}</small>
          </div>
          <b-img :src="hovered ? require('@/assets/images/svg/brandme_ia_primary.svg') : require('@/assets/images/svg/brandme_ia_black.svg')" class="image-icon-brandme-ia-drop" />
        </div>

      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'analyzer'}"
        link-class="d-flex align-items-start menu-dropdown justify-content-between cursor-pointer"
        v-if="userData.main_group.name !== 'creator'"
      >
        <div>
          <span>{{$t('reportsInfluencers')}} </span>
          <small class="text-muted d-flex">{{$t('creator.remainings')}}: {{ separatebycomma(max_view) }}</small>
        </div>
      </b-dropdown-item>
      <b-dropdown-divider class="px-1"/>
      <b-dropdown-item
        :to="{ name: 'dashboard-analytics'}"
        link-class="d-flex align-items-center menu-dropdown"
      >
        <feather-icon size="16" icon="HomeIcon" class="mr-50" />
        <span>{{ $t("dashboard.desk") }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="userData.main_group.name==='creator'"
        :to="{ name: 'my_work-without-section', params: { section: 'networks' } }"
        link-class="d-flex align-items-center menu-dropdown"
      >
        <feather-icon size="16" icon="LinkIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.network") }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-else-if="userData.main_group.name==='whitelabel'"
        :to="{ name: 'pages-account-setting', params: { section: 'social' } }"
        link-class="d-flex align-items-center menu-dropdown"
      >
        <feather-icon size="16" icon="LinkIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.network") }}</span>
      </b-dropdown-item>
      <b-dropdown-item  link-class="d-flex align-items-center menu-dropdown"
        v-if="!showStatus() && !checkOutHome()"
        :to="{ name: 'my_work-without-section', params: { section: 'transactions' } }"
      >
        <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.transaction") }}</span>
      </b-dropdown-item>
      <b-dropdown-item 
        link-class="d-flex align-items-center menu-dropdown"
        :to="{ name: 'pages-account-setting', params: { section: 'membership' }}"
      >
        <b-icon
          icon="card-checklist"
          size="18"
          class="mr-50"
        />
        <span>{{$t('membership')}}</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-account-setting', params: { section: 'general' } }"
        link-class="d-flex align-items-center menu-dropdown"
      >
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.settings") }}</span>
      </b-dropdown-item>

       <b-dropdown-divider class="px-1" v-if="showStatus() && !checkOutHome()"/>
      <b-dropdown-item link-class="d-flex align-items-center menu-dropdown" href="https://brandme.statuspage.io/" target="_blank" v-if="showStatus() && !checkOutHome()">
        <feather-icon
          size="16"
          icon="BarChartIcon"
          class="mr-50"
          variant="light-primary"
        />
        <span>{{$t('header.userMenu.status')}}</span>
      </b-dropdown-item>
      <b-dropdown-divider class="px-1"  v-if="!showStatus() && !checkOutHome()"/>

      <b-dropdown-item link-class="d-flex align-items-center menu-dropdown"
        v-if="!showStatus() && !checkOutHome()" href="https://brandme.la/faq/" target="_blank"
      >
        <feather-icon size="16" icon="PlusSquareIcon" class="mr-50" />
        <span class="container-submenu">{{ $t("header.userMenu.help") }}</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center menu-dropdown"
        v-if="!checkOutHome()" @click="openChatSupport"
      >
      
        <feather-icon size="16" icon="MicIcon" class="mr-50" />
        <span class="container-submenu">{{ $t("header.userMenu.suport") }}</span>
      </b-dropdown-item>
    

      <b-button
        :class="`${visible ? null : 'collapsed'} custom-drop align-items-start d-flex`"
        variant="custom-drop"
        :aria-expanded="visible ? 'true' : 'false'"
        @click="visible = !visible"
      >
      <b-img :src="`${$i18n.locale === 'en' ? locales.en.img : locales.es.img }`" width="16px" :alt="locales.en.locale" />
      <span class="ml-07">{{ this.$i18n.locale === 'en' ? locales.en.name : locales.es.name}}</span>
      </b-button>
    <b-collapse v-model="visible" class="mt-1">
      <div>
        <b-dropdown-item @click="changeLanguage('en')">
          <b-img :src="locales.en.img" width="16px" :alt="locales.en.locale" />
          <span class="ml-07">{{ locales.en.name }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="changeLanguage('es')">
          <b-img :src="locales.es.img" width="16px" :alt="locales.es.locale" />
          <span class="ml-07">{{ locales.es.name }}</span>
        </b-dropdown-item>
      </div>
    </b-collapse>


      <b-dropdown-divider class="px-1"/>
      <b-dropdown-item link-class="d-flex align-items-center menu-dropdown" @click="logout">
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
          variant="light-primary"
        />
        <span>{{ $t("header.userMenu.logout") }}</span>
      </b-dropdown-item></b-nav-item-dropdown>
     </div>
</template>

<script>
import {
  BRow,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BCollapse,
  BImg,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useAppConfig from "@core/app-config/useAppConfig";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { avatar } from "@/assets/images/avatars/usuario.png";
import utils from '@/libs/utils';
import service from "@/services/dashboard";
import service_profile from '@/services/others'
import notification_service from '@/services/notifications'
import { getSummaryCredits } from '@/services/ia'
import { separatebycomma } from '@/libs/utils/formats';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BButton,
    BCollapse,
    BImg,
  },
  data() {
    return {
      hovered: false,
      separatebycomma,
      credits: 0,
      visible: false,
      avatar_file: null,
      utils,
      avatarText,
      avatar,
      check: false,
      domain_path: window.location.host,
      userData: JSON.parse(localStorage.getItem('userData')),
      
      max_view: 0,
    }
  },
  setup() {
    const locales = {
      en: {
        locale: 'en',
        img: require(`@/assets/images/countries/us.svg`),
        name: 'English',
      },
      es: {
        locale: 'es',
        img: require(`@/assets/images/countries/mx.svg`),
        name: 'Español',
      },
      // Agrega más idiomas según sea necesario
    };

    const { isDropdownMenuHidden } = useAppConfig();

    return {
      locales,
      isDropdownMenuHidden,
    };
  },
  created() {
    this.$root.$on('profile_update', () => {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    });
    this.$root.$on('change_max_views', (max_views) => {
      this.max_view = max_views;
    })
    this.$root.$on('update_credits_drop', (consumed_credits) => {
      this.credits = this.credits - consumed_credits;
    })

    this.getSummaryCredits();
    this.getMaxViews();
  },
  methods: {
    getNameRol() {
      const name_plan = this.userData ? this.userData.current_plan.name === 'Free' ? '' : this.userData.current_plan.name : '';
      const is_brand = this.userData && this.userData.main_group.name !== 'creator';

      const name = is_brand ? `${this.$t('creator.advertiser')} ${name_plan}` : `${this.$t('creator.creator')} ${name_plan}`
      return name;
    },
    getSummaryCredits() {
      getSummaryCredits().then((response) => {
        this.credits = response.credits_available;
      })
    },
    getMaxViews() {
      service_profile.getMaxViews().then(response => {
        this.max_view = response.max_views;
      })
    },
    changeLanguage(locale) {
      
      this.$i18n.locale = locale;
      this.visible = false
    },
    redirectEmail() {
      if (this.userData && this.userData.main_group.name === 'creator') {
        if (this.userData.meta_info && this.userData.meta_info.vanity_name) {
          this.$router.push({ name: 'user_profile', params: { user: this.userData.meta_info.vanity_name } });
        } else {
          this.$router.push({ name: 'setting-public-profile', params: { section: 'connections' } });
        }
      } else {
        this.$router.push({ name: 'pages-account-setting', params: { section: 'general'} });
      }

    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      const token_device = localStorage.getItem('token_device')

      notification_service.remove_token(token_device).then(() => {
      })
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("accept_tyc");
      localStorage.removeItem('permissions');
      localStorage.removeItem('token_device');
      localStorage.removeItem('has_showed_notification_modal');
      localStorage.removeItem('routes_visited');
      localStorage.removeItem('stepperInfo');
      localStorage.removeItem('steps');

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      // this.$router.push({ name: "auth-login" });
      window.location.replace("/login");
    },
    openChatSupport() {
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.widget.open();
    },
    status() {
      this.$router.push({ name: "status" });
    },
    isSuperUser() {
      return utils.getUserData().is_superuser
    },
    showStatus() {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData.main_group && userData.main_group.name) return userData.main_group.name !== 'creator';
      return false
    },
    checkOutHome() {
      return this.domain_path.includes('outofhome')
    }
  },
}
</script>
<style scoped>
.padding-nav-item {
  padding: 9.1px 17.9px;
}
.image-icon-brandme-ia-drop {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}

</style>
<style>
.ml-07 {
  margin-left: 7px;
}
.custom-drop {
  color: #212529;
  font-weight: bold;
  border-radius:0px;
  width: 100%;
  padding: 0.786rem 1.1rem;
;
}
.custom-drop:hover {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.12);
  font-weight: bold;
  border-radius:0px;
  width: 100%;
}
.menu-dropdown {
  color: #212529;
  font-weight: bold;
}
.container-email {
  font-size: small;
  padding-left: 15px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container-username {
  font-weight: bold;
  padding-left: 15px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container-submenu {
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.size-submenu {
  width: 230px !important;
}
</style>

