<template>
  <li
    v-if="!isAdmin(item) && $can(item.action, item.resource)"
    class="nav-item item-scroll"
    :class="`${getActive(item)} ${getCustomClass(item)}`"
    :id="`toolTip${item.title}`"
    @click="clickNavBar()"
  >
    <b-link
      :class="getClassLink(item)"    
      @click="redirectDisabled(item)"
      v-if="!item.external && notExcluded(item)"
      class="d-flex align-items-center"
    >
      <feather-icon v-if="item.icon"
        size="14"
        :icon="item.icon"
      />
      
      <b-img v-else-if="item.img_svg"
        :src="require(`@/assets/images/svg/${item.img_svg}.svg`)"
        class="img-nav"
        :class="{'svg-icon-active': !(getActive(item) === 'noActive' || getActive(item) === ''), 'svg-icon-disabled': getDisabled(item)}"
      />
      <span :class="`text-main-menu ${(getActive(item) === 'noActive' || getActive(item) === '') ? 'font-weight-bolder' : ''}`" id="span-menu">{{ $t(`header.menu.${item.title}`) }}</span>
      <b-img
        v-if="getDisabled(item)"
        class="img-crown"
        :src="require(`@/assets/images/svg/crown.svg`)"
      />
      <b-badge class="ml-1" variant="light-primary" v-if="item.newConnect">
        {{$t('connect')}}
      </b-badge>
      <b-badge variant="light-primary" class="ml-1" v-if="item.newLabel">
        {{$t('newFeature')}}
      </b-badge>
    </b-link>
    <b-link
      :class="getClassLink(item)"
      :disabled="getDisabled(item)"
      v-if="item.external && notExcluded(item)"
      :href="item.href"
      target="_blank"
    >
      <feather-icon
        size="14"
        :icon="item.icon"
      />
      <span :class="`text-main-menu ${(getActive(item) === 'noActive') ? 'font-weight-bolder' : ''}`">{{ $t(`header.menu.${item.title}`) }}</span>
      <b-badge class="ml-1" variant="light-primary" v-if="item.newConnect">
        {{$t('connect')}}
      </b-badge>
      <b-badge variant="light-primary" v-if="item.newLabel">
        {{$t('newFeature')}}
      </b-badge>
    </b-link>

    <b-tooltip variant="primary" :target="`toolTip${item.title}`" placement="bottom" class="p-1" v-if="item.newConnect">
      {{$t('nowConnect')}}
    </b-tooltip>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      @close_modal="open_modal_plans_group = false"
      v-if="open_modal_plans_group"
    />
  </li>
</template>

<script>
import {BBadge, BLink, BTooltip, BImg } from 'bootstrap-vue'
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink'

export default {
  components: {
    BImg,
    BLink,
    BBadge,
    BTooltip,
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      role: '',
      is_admin: false,
      view_tracking_links: false,
      open_modal_plans_group: false,
      update_modal: false,

    }
  },
  created() {
    if (localStorage.userData) {
      const user = JSON.parse(localStorage.userData);
      const current_plan = user.current_plan
      this.is_admin = user.is_superuser;
      this.role = (user.groups.length > 0) ? user.groups[0].name : null
      this.view_tracking_links = current_plan.view_tracking_links
    }
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item)

    return {
      isActive,
      updateIsActive,
    }
  },
  methods: {
    redirectDisabled(item) {
      if (!this.getDisabled(item)) {
        this.$router.push({ name: item.route, params: item.params })
      } else {
        this.$emit('open_modal')
      }
    },
    openModal() {
      this.open_modal_plans_group = true
      this.update_modal = !this.update_modal;
    },
    getCustomClass(item) {
      if (item.customClass) return item.customClass
      return ''
    },
    isAdmin(item) {
      return (item.only_admin !== undefined && !this.is_admin);
    },
    getActive(item) {
      if (this.isActive && item.route === 'dashboard-analytics') return 'sidebar-group-active active tab-dashboard'
      if (item.route === 'tracking_links' && this.$router.currentRoute.name === 'detail_tracking_links') return 'sidebar-group-active active'

      if (!this.isActive) return 'noActive'
      if (this.$router.currentRoute.name === 'pages-account-setting') {
        if (this.$router.currentRoute.params.section === item.params.section) return 'sidebar-group-active active'
        else return ''
      }

      return this.isActive ? 'sidebar-group-active active' : ''
    },
    clickNavBar() {
      this.$emit('changeNavBar', this.item)
    },
    getDisabled(item) {
      if (item.permission) {
        return !this.$ability.can("manage", item.permission);
      }

      if (item.verify_plan) {
        if (item.plan_benefit === 'view_tracking_links') {
          return !this.view_tracking_links
        }
      }

      if (item.disabled) return item.disabled;

      if (item.enabled_admin !== undefined) {
        if (item.enabled_admin && this.is_admin) return false; 
        else return true
      } 
      return false;
    },
    getClassLink(item) {
      if (item.permission) return !this.$ability.can('manage', item.permission) ? 'nav-link item-disabled' : 'nav-link'
      if (item.disabled) return item.disabled ? 'nav-link item-disabled' : 'nav-link';
      if (item.verify_plan) {
        if (item.plan_benefit === 'view_tracking_links') {
          return this.view_tracking_links ? 'nav-link' : 'nav-link item-disabled'
        }
      }

      if (item.enabled_admin) return !this.is_admin ? 'nav-link item-disabled' : 'nav-link';
      return 'nav-link';
    },
    notExcluded(item) {
      if (item.exclude) {
        return item.exclude.indexOf(this.role) === -1
      } else return true
    }
  },
}
</script>
<style type="text/css">
  .img-crown {
    width: 22px;
    margin-left: .5em;
  }
  .img-nav {
    width: 19px;
    margin-right: .5em;
  }
  .tab-dashboard  {
    margin-right: 3px !important;
  }
  .tooltip-menu-disabled {
  }
  .item-disabled {
    background: #f5f9fd !important;
  }
  .aRedirect{
    display: block;
    padding: 10px;
  }
  .text-main-menu{
    font-size: 16px;
  }
  @media(min-width:1200px) and (max-width: 1800px){
    .scroll-menu {
      overflow: auto;
      white-space: nowrap;
    }

    .item-scroll {
      display: inline-block;
      text-align: center;
      text-decoration: none;
    }
  }
</style>
<style scoped>
.svg-icon-disabled {
  filter: grayscale(100%) brightness(0%) saturate(0%) contrast(192%) invert(94%);
-webkit-filter: grayscale(100%) brightness(0%) saturate(0%) contrast(192%) invert(94%);
-moz-filter: grayscale(100%) brightness(0%) saturate(0%) contrast(192%) invert(94%);
}
.svg-icon-active {
  filter: brightness(0) invert(1);
}
</style>
