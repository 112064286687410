<template>
  <ul id="main-menu-navigation" class="nav navbar-nav item-scroll" :key="change_tab">
    <component :is="resolveNavComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item"
      @changeNavBar="changeNavBarParent" @open_modal="modal_chat = true"/>
    <modal-brandme  :key="change_modal" :show_modal="modal_chat" :size="'lg'" @hidden="closeModal" :bg_title="'bg-modal-chat'" :bg_body="'bg-modal-body-chat'" :class_footer="'footer-modal-chat'">
      <template #title="">
        <span class="text-white"><b-img class="img-crown svg-crown" :src="require(`@/assets/images/svg/crown.svg`)"></b-img> Included in the Custom
          packages</span>
      </template>
      <template #body="">
        <b-row>
          <b-col>
            <h2 class="mr-10">Brand Protection
            <b-badge class="mr-10 bgd-color" pill>Paid Search</b-badge>
            <b-badge class="bgd-color" pill>SEO</b-badge>
            </h2>

          </b-col>
        </b-row>

        <b-row>
          <b-col class="mb-3 d-flex col-6">
            <span>Monitor paid search ads and get alerts when bids are made on your brands most important search terms.</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-img class="img-modal" :src="require(`@/assets/images/vuexy-login-bg.jpg`)"></b-img>
          </b-col>
          <b-col>
            <h2>Use it to:</h2>
            <b-col class="mt-2" v-for="(item, index) in text_list" :key="index">
              <b-icon :icon="item.icon" class="text-primary mr-10 h2 mb-0"></b-icon>
              <span>{{ item.title }}</span>
            </b-col>
          </b-col>
        </b-row>
      </template>
      <template #footer="">
        <b-button pill variant="ligth text-primary mr-1" @click="closeModal"> Cerrar</b-button>
        <b-button pill variant="primary" @click="openChatSupport"><b-img class="img-crown-btn svg-crown" :src="require(`@/assets/images/svg/crown.svg`)"></b-img> Hablar con nosotros</b-button>
      </template>


    </modal-brandme>
  </ul>
</template>

<script>
import { BImg, BBadge, BCol, BRow, BButton, } from 'bootstrap-vue';
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    BImg,
    BBadge,
    BCol,
    BRow,
    BButton,
    modalBrandme: () => import('@/views/components/modal/ModalBrandme.vue')
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      change_tab: false,
      change_modal: false,
      modal_chat: false,
      text_list: [
        {
          title: 'Discover the ads and messaging your competitors use for your branded keywords.',
          icon: 'check',
        },
        {
          title: 'Get daily/weekly alerts on who is bidding on your paid terms to monitor your competitors.',
          icon: 'check',
        },
        {
          title: 'Target the specific geo you want to track down to the zip code.',
          icon: 'check',
        },
        {
          title: 'Reveal paid search ad ID’s to report malicious ads.',
          icon: 'check',
        },
      ],
    }
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
  methods: {
    closeModal() {
      this.change_modal = !this.change_modal
      this.modal_chat = false
    },
    changeNavBarParent() {
      this.change_tab = !this.change_tab
    },
    openChatSupport() {
      this.change_modal = !this.change_modal
      this.modal_chat = false
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.widget.open();
    },
  }
}
</script>
<style>
.footer-modal-chat {
  display: flex;
  justify-content: end;
  background: rgb(245, 249, 253);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.bg-modal-chat {
  background: rgb(121, 117, 242);
}
.bg-modal-body-chat {
  background: rgb(245, 249, 253);
}
</style>
<style scoped>
.svg-crown {
  filter: brightness(0) invert(1);
}
.bgd-color {
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(181, 190, 198, 0.2);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 4px 8px;
  color:black
}
.img-modal {
  width: 100%;
  border-radius: 5%;
}
.img-crown {
  width: 28px;
  margin-right: .5em;
  vertical-align: bottom;
}
.img-crown-btn {
  width: 20px;
  margin-right: .3em;
  vertical-align:text-bottom
}
.mr-10 {
  margin-right: 10px;
}
</style>
