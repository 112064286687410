<template>
  <div class="navbar-container d-flex content align-items-center" :style="bgColor">

    <!-- Nav Menu Toggler -->
    <div class="d-block d-xl-none h-navbar-main">
      <ul class="nav navbar-nav">
        <li class="nav-item col-12 col-sm-auto">
          <b-link
            class="nav-link d-block"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="text-white menu-icon-navbar"
            />
          </b-link>
          <span class="d-flex justify-content-center ml-5 ml-sm-3">
              <b-img
                v-if="checkCoca()"
                src="@/assets/images/logo/coca_logo.png"
                alt="Logo"
                style="max-width: 100%"
              />
              <b-img
                v-else-if="checkOutHome()"
                fluid
                src="@/assets/images/logo/ooh.avif"
                alt="Logo"
                style="max-width: 100px"
                class="position-absolute-logo-ooh"
              />
              <b-img
                v-else
                src="@/assets/images/logo/logo-brandme.png"
                alt="Logo"
                style="max-width: 100px"
                @click="checkRedirect()"
                class="cursor-pointer"
              />
          </span>
        </li>
      </ul>
    </div>

    <!-- Right Col -->
    <b-navbar-nav :class="`d-flex w-100 position-relative justify-content-end ${utils.getUserData() ? '' : 'justify-content-lg-center'}`">
      <div class="nav align-items-center" v-if="this.$route.name !== 'home_search' && utils.getUserData()">
        <button-modal/>
        <feather-icon 
          icon="PlusIcon" 
          class="add-proposal-button-nav d-none d-sm-block" 
          id="create_proposal" 
          @click="redirectOptionCreateProposal()"
          v-if="utils.getUserData() !== null && rol === 'creator'"
        ></feather-icon>
        <b-icon v-if="!checkOutHome()" icon="headset" class="button-help d-none d-sm-block" @click="openChatSupport"/>
        <b-tooltip target="create_proposal">{{$t('dashboard.createProposal')}}</b-tooltip>
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        <notification-dropdown class="d-none d-sm-block" v-if="utils.getUserData() !== null && !checkOutHome()"/>
        <user-dropdown v-if="utils.getUserData() !== null"/>
      </div>
      <div v-else-if="utils.getUserData()" class="d-flex align-items-center">
        <span class="m-0 text-white mr-1 cursor-pointer align-items-center d-flex white-space-nowrap" @click="$router.push({name: 'brandme_AI', params: {section: 'community'}})">
          <b-img :src="require('@/assets/images/svg/brandme_ia_white.svg')" class="image-icon-brandme-ia"></b-img>
          <span class="d-none d-md-block">BrandMe IA</span>
        </span>
        <span class="text-white cursor-pointer mr-1 d-none d-md-block" @click="$router.push({name: 'dashboard-analytics'})">{{$t('dashboard.desk')}}</span>
        <span class="text-white cursor-pointer mr-1 d-none d-md-block" @click="$router.push({name: 'casting_calls'})">{{$t('dashboard.campaigns')}}</span>
        <span class="text-white cursor-pointer mr-1 d-none d-md-block" @click="$router.push({name: 'chat-proposal'})">Chat</span>
        <b-link href="https://brandme.la/faq/" target="_blank"><b-icon icon="headset" class="button-help d-none d-sm-block" /></b-link>
        <user-dropdown v-if="utils.getUserData() !== null"/>
      </div>

      <div v-if="!utils.getUserData() && isInCreatorView()" class="d-none d-lg-flex align-items-center">
        <a class="m-0 text-white mr-1 cursor-pointer d-flex align-items-center" href="https://brandme.la/ia">
          <b-img :src="require('@/assets/images/svg/brandme_ia_white.svg')" class="image-icon-brandme-ia"></b-img>
          <span class="">BrandMe IA</span>
        </a>
        <b-nav-item-dropdown class="mr-1" v-for="(button, index) in links" :key="index">
          <template #button-content>
            <p class="m-0 text-white">
              {{ button.name_button }}
              <feather-icon icon="ChevronDownIcon"></feather-icon>
            </p>
          </template>
          <b-dropdown-item v-for="(link, index_options) in button.options" :key="index_options" class="dropdown-item-options-link" @click="redirectionRoute(link.url)">
            <b-link>{{ link.text }}</b-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button v-if="!utils.getUserData() && isInCreatorView()" pill variant="outline-light" class="button-session-outline"  @click="open_modal_login = true; update_modal_login = !update_modal_login">{{ $t('login_text') }}</b-button>
      </div>

      <div class="d-flex align-items-center d-lg-none mr-2" v-if="!utils.getUserData() && isInCreatorView()">
        <b-button  class="d-none d-sm-block" pill variant="outline-light" @click="open_modal_login = true; update_modal_login = !update_modal_login">{{ $t('login_text') }}</b-button>
        <div @click="open_sidebar = true">
          <b-avatar variant="" class="ml-1 cursor-pointer avatar-nav-responsive"></b-avatar>
        </div>
      </div>
    </b-navbar-nav>


    <b-sidebar v-model="open_sidebar" backdrop shadow right class="sidebar-nav-responsive-public d-lg-none" v-if="!utils.getUserData() && isInCreatorView()">
      <template #header>
        <div class="d-flex justify-content-end w-100 p-1">
          <feather-icon 
            icon="XIcon" 
            size="18" 
            class="d-block close-sidebar-icon"
            @click="open_sidebar = false"
          >
          </feather-icon>
        </div>
      </template>
      <div class="p-1">
        <a class="m-0 text-white cursor-pointer d-flex align-items-center item-list p-1" href="https://brandme.la/ia">
          <b-img :src="require('@/assets/images/svg/brandme_ia_white.svg')" class="image-icon-brandme-ia"></b-img>
          BrandMe IA
        </a>
        <div v-for="(button, index) in links" :key="index">
          <div class="d-flex justify-content-between align-items-center cursor-pointer item-list p-1" @click="button.collapse = !button.collapse">
            <span>{{button.name_button}}</span>
            <feather-icon :icon="!button.collapse ? 'PlusIcon' : 'MinusIcon'"></feather-icon>
          </div>
          <b-collapse title="Creators" v-model="button.collapse">
            <div class="p-1 item-list ml-1" v-for="(link, index_options) in button.options" :key="index_options" @click="redirectionRoute(link.url)">
              <b-link class="text-white">{{ link.text }}</b-link>
            </div>
          </b-collapse>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button pill variant="outline-light" @click="open_modal_login = true; update_modal_login = !update_modal_login">{{ $t('login_text') }}</b-button>
        </div>

      </div>
    </b-sidebar>
    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
  </div>
</template>

<script>
import {
  BIcon,
  BLink,
  BNavbarNav,
  BImg,
  BTooltip,
  BButton,
  BAvatar,
  BSidebar,
  BCollapse,
  BNavItemDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
// import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import ButtonModal from '../ButtonModal.vue'
import useAppConfig from "@core/app-config/useAppConfig";
import { watch } from '@vue/composition-api';
import utils from '@/libs/utils';
import NotificationDropdown from './components/NotificationDropdown.vue';
import ModalLogin from '@/views/components/modal/ModalLogin.vue'
import { eventClosed } from '@/libs/utils/hubspot';
import { getUserData } from '@/libs/utils/user';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  data () {
    return {
      utils,
      rol: '',
      bgColor: '',
      dominio: window.location.host,
      open_modal_login: false,
      update_modal_login: false,
      open_sidebar: false,
      is_creador: utils.getUserData() && utils.getUserData().is_superuser ? utils.getUserData().main_group.name === 'creator' : false,
      links: [
        {
          name_button: 'Marcas',
          options: [
            {text: 'BrandMe Finder', url: 'https://brandme.la/brandme-finder'},
            {text: 'BrandMe Influencer Suite', url: 'https://brandme.la/brandme-influencer-marketing-suite/'},
            {text: 'Servicio Personalizado', url: 'https://brandme.la/servicios/'},
            {text: 'BrandMe | Product Placement ', url: 'https://brandme.la/brandme-product-placement-patrocinadores/'},
            {text: 'Contrata Celebridades', url: 'https://brandme.la/contrata-celebridades/'},
            {text: 'Casos de Éxito', url: 'https://brandme.la/casos-de-exito/'},
          ],
          collapse: false
        },
        {
          name_button: 'Creadores',
          options: [
            {text: 'Beneficios a Influenciadores & Creadores', url: 'https://brandme.la/influencers-creadores/'},
            {text: 'Consigue Patrocinadores', url: 'https://brandme.la/consigue-patrocinadores/'},
            {text: 'BrandMe Talent', url: 'https://brandme.la/brandme-talent/'},
          ],
          collapse: false
        },
        {
          name_button: 'Recursos',
          options: [
            {text: '¿ Cuánto cobrar por mis Posts en Instagram ?', url: 'https://brandme.la/cuanto-cobrar-en-instagram/'},
            {text: '¿ Cuánto dinero puedo ganar siendo YouTuber ?', url: 'https://brandme.la/cuanto-gano-siendo-youtuber/'},
            {text: 'Generador de Hashtags en Instagram', url: 'https://brandme.la/generador-de-hashtags-en-instagram/'},
            {text: 'MicroInfluencers vs Celebridades', url: 'https://brandme.la/microinfluencers-vs-celebridades/'},
            {text: 'BrandMe Shop', url: 'https://shop.brandme.la/'},
            {text: 'Comunidad', url: 'https://brandme.la/comunidad/historias/'},
          ],
          collapse: false
        }
      ]
    }
  },

  components: {
    BButton,
    BIcon,
    BImg,
    BCollapse,
    BAvatar,
    BLink,
    BTooltip,
    BNavItemDropdown,
    BDropdownItem,
    BSidebar,
    // Navbar Components
    BNavbarNav,
    // DarkToggler,
    UserDropdown,
    ButtonModal,
    NotificationDropdown,
    ModalLogin,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    const { skin } = useAppConfig();
      
    watch(skin , valu => {
      if (valu === 'dark' && this.dominio.includes('outofhome'))
        this.bgColor = 'background: #273045'
      else if (this.dominio.includes('outofhome')) this.bgColor = 'background: #e74353'
    })
  },
  computed: {
    can_create_proposal() {
      return getUserData() && getUserData().meta_info;
    }
  },
  created () {
    this.rol = utils.getUserData() !== null ? utils.getUserData().groups.length > 0 ? utils.getUserData().groups[0].name : '' : '';
    if (this.dominio.includes('coca')) this.bgColor = 'background: #f01f33'
    else if (this.dominio.includes('outofhome')) this.bgColor = 'background: #e74353'
    else this.bgColor = 'background: linear-gradient(120deg ,#6947bc, #993e90'
  },
  methods: { 
    redirectionRoute(url) {
      window.location.href = url;
    },
    openChatSupport() {
      const status = window.HubSpotConversations.widget.status();
      if (!status.loaded) {
        window.HubSpotConversations.widget.load({ widgetOpen: true });
        window.HubSpotConversations.on('widgetClosed', eventClosed);
      } else window.HubSpotConversations.widget.open();
    },
    checkRedirect() {
      this.$router.push({name: 'home_search'})
    },
    isInCreatorView() {
      const route = this.$route.name;
      const views_public_creator = [
        'user_profile',
        'home_search',
        'view_proposal',
        'search_proposal',
        'casting_calls',
        'view_casting_call'
      ];
      return (views_public_creator.find((view_creator) => view_creator === route));
    },
    redirectOptionCreateProposal() {
      if (this.can_create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
    checkCoca() {
      return this.dominio.includes('coca')
    },
    checkOutHome() {
      return this.dominio.includes('outofhome')
    },
  }
}
</script>
<style>
.button-session-outline {
  position: absolute;
  right: 0;
  top: -0.6em;
}
.dropdown-item-options-link {
  font-size: 12px;
}
.white-space-nowrap {
  white-space: nowrap;
}
.bm-logo{
  margin-left: 15px;
  margin-top: 8px;
}
.menu-icon-navbar {
  position: absolute;
  top: 5px;
}
.h-navbar-main {
  position: absolute;
  z-index: 100;
  height: 35px !important;
}
.item-list {
  font-size: 12px;
}
@media (max-width: 575px) {
  .position-absolute-logo-ooh {
    position: absolute;
    top: -8px;
  }
}
@media (min-width: 575px) {
  .position-absolute-logo-ooh {
    position: absolute;
    top: -8px;
    left: 2.5em;
  }
  .navbar-options-user {
    position: absolute;
    right: 2em;
  }
}
.sidebar-nav-responsive-public .bg-light {
  color: white !important;
  background: linear-gradient(0deg ,#6947bc, #993e90) !important;
}
.sidebar-nav-responsive-public .item-list:hover {
  background-color: rgba(0, 0, 0, 0.489);
  transition: all 500ms;
  border-radius: 0.5em;
}
</style>
<style scoped>
.image-icon-brandme-ia {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.add-proposal-button-nav {
  color: white;
  margin: 0 1em 0 0;
  padding: 8px;
  width: 3.125rem;
  height: 3.125rem;
}
.add-proposal-button-nav:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10em;
  cursor: pointer;
}
.button-help {
  color: white;
  padding: 11px;
  width: 3.125rem;
  height: 3.125rem;
  cursor: pointer;
}
.button-help:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10em;
  cursor: pointer;
}
</style>